<template lang="pug">
  .app-pagination
    slot(name="prepend")
      div
    .app-pagination-main
      BPagination.pager(
        :value="currentPage"
        :total-rows="total"
        :per-page="perPage"
        first-text="<<"
        prev-text="<"
        next-text=">"
        last-text=">>"
        @page-click="handleChangeCurrentPage"
      )
      .per-page
        AppDropdown(
          :lazy="false"
          flip-on-update
          z-index="1000"
          close-on-select
          value-key="value"
          title-key="value"
          placement="top"
          :items="paginationTypes"
          :value="{ value: this.perPage }"
          @select="handleChangePerPage"
        )
</template>

<script>
  // mixins
  import withScrollTop from "@/mixins/withScrollTop"

  const perPageOptions = [10, 25, 50, 100]

  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      perPage: {
        type: Number,
        required: true
      },
      total: {
        type: Number,
        required: true
      },
      // {
      //   container: ".selector",
      //   target: ".selector",
      //   behavior: "smooth"
      // }
      autoScrollOnPagination: {
        type: Object,
        default: () => new Object()
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    mixins: [withScrollTop],

    computed: {
      paginationTypes() {
        return perPageOptions.map(value => ({ value }))
      }
    },

    methods: {
      handleChangeCurrentPage(event, current_page) {
        // current page is calculated in the outside this componnent
        // we don't need to change current page inside BPagination component
        event.preventDefault()
        this.handleChange({ current_page, per_page: this.perPage })
      },

      handleChangePerPage({ value }) {
        this.handleChange({ current_page: 1, per_page: value })
      },

      handleChange(paginationData) {
        this.$emit("change-pagination-data", paginationData)
        // scroll table container to top
        this.scrollTo(this.autoScrollOnPagination)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/helpers.sass"

  .app-pagination
    display: flex
    justify-content: space-between
    font-size: 0.8rem
    margin-top: $pagination-margin-top
    margin-bottom: $pagination-margin-bottom

    .pager
      margin-top: 6px

    &-main
      display: flex
      gap: 0.5rem

    ul
      margin: 0

    ::v-deep
      .page-item
        &:last-of-type
          .page-link
            border-right: 1px solid $border-element-color

        display: table
        width: 37px
        height: $pagination-height
        margin-bottom: 0

        .page-link
          background-color: $default-white
          border: 1px solid $border-element-color
          border-right: none
          box-shadow: none
          color: $default-black
          display: table-cell
          height: $pagination-height
          padding: 0
          text-align: center
          vertical-align: middle
          width: 100%

        &.active
          .page-link
            background-color: $default-purple
            border: 1px solid $default-purple
            color: $default-white
            z-index: inherit !important

        &.disabled
          .page-link
            color: $default-gray

      .per-page
        min-width: 80px
</style>
